import {appVersion} from './version';

export const environment = {
  production: false,
  websocketUrl: 'wss://stage.frankli.io/livesync',
  apiUrl: 'https://stage.frankli.io/api',
  sso: {
    redirectUri: 'https://stage.frankli.io/callback',
    logoutUri: 'https://stage.frankli.io',
    microsoft: {
      clientId: '56711260-943e-4081-9e4e-5babef909539',
    },
    google: {
      clientId: '62249310443-l2njm2u355foac6jbra4ga1799o15rjk.apps.googleusercontent.com',
    }
  },
  sentry: {
    enabled: true,
    environment: 'stage',
    key: 'https://c07fba51f1fb4eb1903d83f2551e07d2@sentry.io/1231287',
  },
  intercom: {
    id: 'yrb9n1sk',
    enabled: false
  },
  vincere: {
    redirectUri: 'https://stage.frankli.io/callback-vincere'
  },
  zendesk: {
    redirectUri: 'https://stage.frankli.io/callback-zendesk',
    clientId: 'zdg-frankli-io'
  },
  password: {
    minimumLength: 8,
    maximumLength: 100
  },
  integrations: {
    slack: {
      enabled: true
    }
  },
  mock: {
    enabled: false,
    apiUrl: 'https://stage.frankli.io'
  },
  version: appVersion,
  baseUrl: 'https://stage.frankli.io',
  tinymceLicenseKey: 'T7LK255CA00F2AFF2B22472B2C55F7912AB0116AC975E680B9E01B19762D7BB8'
};
